import {
  getPortisDappId,
  getFortmaticApiKey,
  getOptimismEthNode,
} from '../local-settings'

const FORMATIC_KEY = getFortmaticApiKey()
const PORTIS_ID = getPortisDappId()
const OPTIMISM_ETH_NODE = getOptimismEthNode()

export const connectors = [
  {
    id: 'injected',
    properties: {
      chainId: [
        1,
        5,
        10,
        137,
        80001,
        1666600000,
        1666700000,
        97,
        56,
        588,
        1088,
      ], // add here to handle more injected chains
    },
  },
  {
    id: 'frame',
    properties: {
      chainId: 10,
    },
  },
  {
    id: 'walletconnect',
    properties: {
      rpc: {
        10: OPTIMISM_ETH_NODE,
      },
      bridge: 'https://walletconnect-relay.minerva.digital',
      pollingInterval: 12000,
    },
  },
  FORMATIC_KEY
    ? {
        id: 'fortmatic',
        properties: {
          chainId: 10,
          apiKey: FORMATIC_KEY,
        },
      }
    : null,
  PORTIS_ID
    ? {
        id: 'portis',
        properties: {
          dAppId: PORTIS_ID,
          chainId: [10],
        },
      }
    : null,
].filter(p => p)

// the final data that we pass to use-wallet package.
export const useWalletConnectors = connectors.reduce((current, connector) => {
  current[connector.id] = connector.properties || {}
  return current
}, {})
